import axios from 'axios';
import FileSaver from 'file-saver';
import config from '../../Config/AEConfig';
import {
  FETCH_MOMENT_OPTIONS_WAITING,
  FETCH_MOMENT_OPTIONS_SUCCESS,
  FETCH_MOMENT_OPTIONS_ERROR,
  FETCH_EVENT_REPORT_WAITING,
  FETCH_EVENT_REPORT_SUCCESS,
  FETCH_EVENT_REPORT_ERROR,
  DOWNLOAD_EVENT_REPORT_WAITING,
  DOWNLOAD_EVENT_REPORT_SUCCESS,
  DOWNLOAD_EVENT_REPORT_ERROR,
  FETCH_EVENT_STATISTIC_WAITING,
  FETCH_EVENT_STATISTIC_SUCCESS,
  FETCH_EVENT_STATISTIC_ERROR,
  FETCH_EVENT_STATISTIC_RESET,
  DOWNLOAD_EVENT_STATISTIC_WAITING,
  DOWNLOAD_EVENT_STATISTIC_SUCCESS,
  DOWNLOAD_EVENT_STATISTIC_ERROR,
  DOWNLOAD_EVENT_STATISTIC_RESET,
} from '../Types';

import ErrorAction from './ErrorAction';

const MODULE_ID = 36;

export const fetchMomentsOptions =
  (authKey, ws_id, plParent, module_id = MODULE_ID) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_MOMENT_OPTIONS_WAITING });
    const url = `${config.BASE_URL}/moments_options?module_id=${module_id}&ws_id=${ws_id}&pl_parent=${plParent}`;
    //console.log('fetch moments_options ', url);
    axios
      .get(url)
      .then((response) => {
        // console.log('fetch moments_options success', response);
        dispatch({
          type: FETCH_MOMENT_OPTIONS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        console.error('fetch moments_options error', error);
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_MOMENT_OPTIONS_ERROR)
          : FETCH_MOMENT_OPTIONS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchEventReport =
  ({
    authKey,
    ws_id,
    module_id = MODULE_ID,
    place_ids = null,
    performer_ids = null,
    eventcategory_ids = null,
    ev_types = null,
    mt_keys = null,
    client_place_ids = null,
    display_place_ids = null,
    source_contact_methods = null,
    triage_ids = null,
    tag_ids = null,
    limitByPublicHoliday = false,
    searchterm = null,
    startdate = null,
    enddate = null,
    start = 1, // initial page
    sort = null,
    start_time = null,
    end_time = null,
    removeDutyHours = false,
    onlyDutyHours = false,
  }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_EVENT_REPORT_WAITING });
    let url = `${config.BASE_URL}/moments?module_id=${module_id}&ws_id=${ws_id}`;
    url += place_ids ? `&place_ids=${place_ids.join()}` : '';
    url += performer_ids ? `&performer_ids=${performer_ids.join()}` : '';
    url += eventcategory_ids
      ? `&eventcategory_ids=${eventcategory_ids.join()}`
      : '';
    url += ev_types ? `&ev_types=${ev_types.join()}` : '';
    url += mt_keys ? `&mt_keys=${mt_keys.join()}` : '';
    url += client_place_ids ? `&client_place_ids=${client_place_ids}` : '';
    url += display_place_ids ? `&display_place_ids=${display_place_ids}` : '';
    url += source_contact_methods
      ? `&source_contact_method_ids=${source_contact_methods}`
      : '';
    url += triage_ids ? `&triage_ids=${triage_ids}` : '';
    url += tag_ids ? `&tag_ids=${tag_ids}` : '';
    url += limitByPublicHoliday
      ? `&public_holidays=${limitByPublicHoliday}`
      : '';
    url += searchterm ? `&searchterm=${searchterm}` : '';
    url += startdate ? `&startdate=${startdate}` : '';
    url += enddate ? `&enddate=${enddate}` : '';
    url += start ? `&start=${start}` : '';
    url += sort ? `&sort=${sort}` : '';
    url += start_time ? `&start_time=${start_time}` : '';
    url += end_time ? `&end_time=${end_time}` : '';
    url += removeDutyHours ? `&remove_duty_hours=true` : '';
    url += onlyDutyHours ? `&only_duty_hours=true` : '';
    axios
      .get(url)
      .then((response) => {
        const payload = {
          data: response.data,
          startdate: startdate,
          enddate: enddate,
        };
        dispatch({ type: FETCH_EVENT_REPORT_SUCCESS, payload });
      })
      .catch((error) => {
        console.error('fetchEventReport error', error);
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_EVENT_REPORT_ERROR)
          : FETCH_EVENT_REPORT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const downloadEventReport =
  ({
    authKey,
    ws_id,
    module_id = MODULE_ID,
    place_ids = null,
    performer_ids = null,
    eventcategory_ids = null,
    ev_types = null,
    mt_keys = null,
    client_place_ids = null,
    source_contact_methods = null,
    triage_ids = null,
    tag_ids = null,
    limitByPublicHoliday = false,
    searchterm = null,
    startdate = null,
    enddate = null,
    sort = null,
    reportType = 'xls',
    start_time = null,
    end_time = null,
    removeDutyHours = false,
    onlyDutyHours = false,
  }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: DOWNLOAD_EVENT_REPORT_WAITING, payload: reportType });
    let url = `${config.BASE_URL}/moments?module_id=${module_id}&ws_id=${ws_id}`;
    url += place_ids ? `&place_ids=${place_ids.join()}` : '';
    url += performer_ids ? `&performer_ids=${performer_ids.join()}` : '';
    url += eventcategory_ids
      ? `&eventcategory_ids=${eventcategory_ids.join()}`
      : '';
    url += ev_types ? `&ev_types=${ev_types.join()}` : '';
    url += mt_keys ? `&mt_keys=${mt_keys.join()}` : '';
    url += client_place_ids ? `&client_place_ids=${client_place_ids}` : '';
    url += source_contact_methods
      ? `&source_contact_method_ids=${source_contact_methods}`
      : '';
    url += triage_ids ? `&triage_ids=${triage_ids}` : '';
    url += tag_ids ? `&tag_ids=${tag_ids}` : '';
    url += limitByPublicHoliday
      ? `&public_holidays=${limitByPublicHoliday}`
      : '';
    url += searchterm ? `&searchterm=${searchterm}` : '';
    url += startdate ? `&startdate=${startdate}` : '';
    url += enddate ? `&enddate=${enddate}` : '';
    url += sort ? `&sort=${sort}` : '';
    url += reportType ? `&report_type=${reportType}` : '';
    url += start_time ? `&start_time=${start_time}` : '';
    url += end_time ? `&end_time=${end_time}` : '';
    url += removeDutyHours ? `&remove_duty_hours=true` : '';
    url += onlyDutyHours ? `&only_duty_hours=true` : '';
    axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        let fileName;
        try {
          fileName = response.headers['content-disposition']
            .split(';')[1]
            .split('=')[1];
        } catch {
          fileName = `sold-items-report-${new Date().getTime()}.${reportType}`;
        }
        FileSaver(blob, fileName);
        dispatch({
          type: DOWNLOAD_EVENT_REPORT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        console.error('downloadEventReport error', error);
        const type = error.response
          ? ErrorAction(error.response.status, DOWNLOAD_EVENT_REPORT_ERROR)
          : DOWNLOAD_EVENT_REPORT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchEventStatistic =
  ({
    authKey,
    wsId,
    plParent,
    eventId,
    place_ids = null,
    client_place_ids = null,
    display_place_ids = null,
    performer_ids = null,
    eventcategory_ids = null,
    ev_types = null,
    mt_keys = null,
    startdate = null,
    enddate = null,
    lang,
    statisticType,
    start_time = null,
    end_time = null,
    pPercent = null,
    root_place_ids = null,
    hideZeros = null,
    hideTurku = null,
    hideOulu = null,
    removeDutyHours = null,
    onlyDutyHours = null,
  }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_EVENT_STATISTIC_WAITING });
    let url = `${config.BASE_URL}/moments/statistic?module_id=${MODULE_ID}&ws_id=${wsId}&event_id=${eventId}&lang=${lang}&stat_type=${statisticType}&pl_parent=${plParent}`;
    url += place_ids ? `&place_ids=${place_ids.join()}` : '';
    url += client_place_ids
      ? `&client_place_ids=${client_place_ids.join()}`
      : '';
    url += display_place_ids
      ? `&display_place_ids=${display_place_ids.join()}`
      : '';
    url += root_place_ids ? `&root_place_ids=${root_place_ids.join()}` : '';
    url += performer_ids ? `&performer_ids=${performer_ids.join()}` : '';
    url += eventcategory_ids
      ? `&eventcategory_ids=${eventcategory_ids.join()}`
      : '';
    url += ev_types ? `&ev_types=${ev_types.join()}` : '';
    url += mt_keys ? `&mt_keys=${mt_keys.join()}` : '';
    url += startdate ? `&startdate=${startdate}` : '';
    url += enddate ? `&enddate=${enddate}` : '';
    url += start_time ? `&start_time=${start_time}` : '';
    url += end_time ? `&end_time=${end_time}` : '';
    url += pPercent ? `&p_percent=${pPercent}` : '';
    url += hideZeros ? `&hide_zeros=true` : '';
    url += hideTurku ? `&hide_turku=true` : '';
    url += hideOulu ? `&hide_oulu=true` : '';
    url += removeDutyHours ? `&remove_duty_hours=true` : '';
    url += onlyDutyHours ? `&only_duty_hours=true` : '';

    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_EVENT_STATISTIC_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        console.error('fetchEventStatistic error', error);
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_EVENT_STATISTIC_ERROR)
          : FETCH_EVENT_STATISTIC_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const downloadEventStatistic =
  ({
    authKey,
    wsId,
    plParent,
    eventId,
    place_ids = null,
    client_place_ids = null,
    display_place_ids = null,
    performer_ids = null,
    eventcategory_ids = null,
    ev_types = null,
    mt_keys = null,
    startdate = null,
    enddate = null,
    lang,
    statisticType,
    start_time = null,
    end_time = null,
    reportType,
    excelGraphic = false,
    root_place_ids = null,
    hideZeros = false,
    hideTurku = null,
    hideOulu = null,
    removeDutyHours = null,
    onlyDutyHours = null,
  }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: DOWNLOAD_EVENT_STATISTIC_WAITING });
    let url = `${config.BASE_URL}/moments/statistic?module_id=${MODULE_ID}&ws_id=${wsId}&event_id=${eventId}&lang=${lang}&pl_parent=${plParent}`;
    url += statisticType ? `&stat_type=${statisticType}` : '';
    url += reportType ? `&report_type=${reportType}` : '';
    url += place_ids ? `&place_ids=${place_ids.join()}` : '';
    url += client_place_ids
      ? `&client_place_ids=${client_place_ids.join()}`
      : '';
    url += display_place_ids
      ? `&display_place_ids=${display_place_ids.join()}`
      : '';
    url += performer_ids ? `&performer_ids=${performer_ids.join()}` : '';
    url += root_place_ids ? `&root_place_ids=${root_place_ids.join()}` : '';

    url += eventcategory_ids
      ? `&eventcategory_ids=${eventcategory_ids.join()}`
      : '';
    url += ev_types ? `&ev_types=${ev_types.join()}` : '';
    url += mt_keys ? `&mt_keys=${mt_keys.join()}` : '';
    url += startdate ? `&startdate=${startdate}` : '';
    url += enddate ? `&enddate=${enddate}` : '';
    url += start_time ? `&start_time=${start_time}` : '';
    url += end_time ? `&end_time=${end_time}` : '';
    url += excelGraphic ? '&excel_graphic=true' : '';
    url += hideZeros ? '&hide_zeroes=true' : '';
    url += hideTurku ? `&hide_turku=true` : '';
    url += hideOulu ? `&hide_oulu=true` : '';
    url += removeDutyHours ? `&remove_duty_hours=true` : '';
    url += onlyDutyHours ? `&only_duty_hours=true` : '';
    axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        let fileName;
        try {
          fileName = response.headers['content-disposition']
            .split(';')[1]
            .split('=')[1];
        } catch {
          fileName = `event-statistic-report-${new Date().getTime()}.${reportType}`;
        }
        FileSaver(blob, fileName);
        dispatch({
          type: DOWNLOAD_EVENT_STATISTIC_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DOWNLOAD_EVENT_STATISTIC_ERROR)
          : DOWNLOAD_EVENT_STATISTIC_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchEventStatistic = () => ({
  type: FETCH_EVENT_STATISTIC_RESET,
});
export const resetDownloadEventStatistic = () => ({
  type: DOWNLOAD_EVENT_STATISTIC_RESET,
});
