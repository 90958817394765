import _ from 'lodash';
import {
  CREATE_EVENT_WAITING,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,
  FETCH_EVENTS_WAITING,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_SUCCESS_NEW,
  FETCH_EVENTS_ERROR,
  FETCH_EVENTS_RESET,
  FETCH_EVENTSX_SUCCESS,
  FETCH_EVENT_WAITING,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_ERROR,
  FETCH_EVENT_RESET,
  EDIT_EVENT_WAITING,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_ERROR,
  DELETE_EVENT_WAITING,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  DELETE_HAND_PROGRAM_WAITING,
  DELETE_HAND_PROGRAM_SUCCESS,
  DELETE_HAND_PROGRAM_ERROR,
  DELETE_HAND_PROGRAM_RESET,
  UPDATE_EVENT,
  AUTH_RESET,
  COPY_EVENT_WAITING,
  COPY_EVENT_SUCCESS,
  COPY_EVENT_ERROR,
} from '../Types';

const INIT_STATE = {
  events: null,
  status: 'init',
  fetchStatus: 'init',
  fetchesStatus: 'init',
  createStatus: 'init',
  editStatus: 'init',
  deleteStatus: 'init',
  priceAddStatus: 'init',
  priceEditStatus: 'init',
  deleteProgram: 'init',
  copyStatus: 'init',
};

export default function EventReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_EVENTS_WAITING: {
      // const val = action.payload ? { [action.payload.id]: { fetchesStatus: 'waiting' } } : { fetchesStatus: 'waiting' };
      const val = { fetchesStatus: 'waiting' };
      return { ...state, ...val };
    }
    case FETCH_EVENTS_ERROR: {
      // const val = action.payload ? { [action.payload.id]: { fetchesStatus: 'error' } } : { fetchesStatus: 'error' };
      const val = { fetchesStatus: 'error' };
      return { ...state, events: null, ...val, error: action.payload.error };
    }
    case FETCH_EVENTS_SUCCESS: {
      //id -> parent Id from action, used to avoid overriding concurrent API call results while building a menu
      const events = _.mapKeys(action.payload.data, 'event_id');
      // const val = action.payload.id ? { [action.payload.id]: { events, status: 'success' } } : { events, status: 'success' };
      // console.log('Id: ', action.payload.id, '\nValue: ', val, '\nEvents: ', events, '\nAction: ', action.payload.data);
      // >>> this is absurd <<<
      const eventsx = action.payload.data;
      return {
        ...state,
        eventsx,
        headers: action.payload.headers,
        events,
        fetchesStatus: 'success',
      };
    }
    case FETCH_EVENTS_SUCCESS_NEW: {
      return {
        ...state,
        headers: action.payload.headers,
        events: action.payload.data,
        fetchesStatus: 'success',
      };
    }
    case FETCH_EVENTS_RESET:
      return { ...INIT_STATE };

    case CREATE_EVENT_WAITING:
      return { ...state, createStatus: 'waiting' };
    case CREATE_EVENT_ERROR:
      return { ...state, createStatus: 'error', error: action.payload.error };
    case CREATE_EVENT_SUCCESS: {
      const created = action.payload.data;
      const events = { ...state.events, [created.event_id]: created };
      //console.log('created:', created);
      return {
        ...state,
        created,
        events,
        status: 'success',
        createStatus: 'success',
      };
    }

    case FETCH_EVENT_WAITING:
      return { ...state, fetchStatus: 'waiting' };
    case FETCH_EVENT_SUCCESS:
      return { ...state, event: action.payload.data, fetchStatus: 'success' };
    case FETCH_EVENT_ERROR:
      return { ...state, fetchStatus: 'error', error: action.payload.error };

    case FETCH_EVENT_RESET:
      return { ...INIT_STATE };

    case FETCH_EVENTSX_SUCCESS: {
      return { ...state };
    }

    case DELETE_EVENT_WAITING:
      return { ...state, deleteStatus: 'waiting' };
    case DELETE_EVENT_SUCCESS: {
      const events = _.omit(state.events, action.payload.data.event_id);
      return { ...state, events, deleteStatus: 'success' };
    }
    case DELETE_EVENT_ERROR:
      return { ...state, deleteStatus: 'error', error: action.payload.error };

    case DELETE_HAND_PROGRAM_WAITING: {
      return { ...state, deleteProgram: 'waiting' };
    }
    case DELETE_HAND_PROGRAM_SUCCESS: {
      const children = state.event.hand_program[0].children.filter(
        (c) => c.cms_content_id !== action.payload.data.cms_content_id
      );
      const newState = {
        ...state,
        deleteProgram: 'success',
        event: {
          ...state.event,
          hand_program: [{ ...state.event.hand_program[0], children }],
        },
      };
      return newState;
    }
    case DELETE_HAND_PROGRAM_ERROR: {
      return { ...state, deleteProgram: 'error', error: action.payload.error };
    }
    case DELETE_HAND_PROGRAM_RESET: {
      return { ...state, deleteProgram: 'init' };
    }

    case EDIT_EVENT_WAITING:
      return { ...state, editStatus: 'waiting' };
    case EDIT_EVENT_SUCCESS: {
      const edited = action.payload.data;
      const events = { ...state.events, [edited.event_id]: edited };
      return { ...state, events, editStatus: 'success' };
    }
    case EDIT_EVENT_ERROR:
      return { ...state, editStatus: 'error', error: action.payload.error };
    case UPDATE_EVENT:
      if (state.events) {
        const index = state.events.findIndex(
          (e) => e.event_id === action.payload.event_id
        );
        if (index !== -1) {
          state.events[index] = action.payload;
        }
      }
      return state;

    case COPY_EVENT_WAITING:
      return { ...state, copyStatus: 'waiting' };
    case COPY_EVENT_SUCCESS:
      return {
        ...state,
        copyStatus: 'success',
        copiedEventId: action.payload.new_event_id,
      };
    case COPY_EVENT_ERROR:
      return {
        ...state,
        copyStatus: 'error',
        error: action.payload.error,
      };

    default:
      return state;
  }
}
