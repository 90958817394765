/* eslint-env browser */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Button, Menu, Input, Accordion, Icon } from 'semantic-ui-react';
import {
  fetchCmsContents,
  fetchPerformers,
  fetchVenues,
  fetchPersons,
  fetchProducts,
  fetchPriceLists,
  fetchLotteries,
  fetchVotings,
  fetchUsers,
  fetchCardInstances,
  fetchTicketingStatus,
} from '../Redux/actions';
import { T } from '../components/Common/Helpers';
import TeamListSeasonFilterController from '../components/PerformerTeams/TeamListSeasonFilterController';
import '../Style/TeamFilter.css';

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { search: '', activeIndex: -1 };
    this.handleSearch = _.debounce(this.handleSearch, 750);
    this.handleOnUpdate = this.handleOnUpdate.bind(this);
    this.handleActiveAccordion = this.handleActiveAccordion.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      this.props.search !== ''
    ) {
      this.setState({ search: '' });
    }
  }

  onChange(e) {
    this.setState({ search: e.target.value });
    this.handleSearch(e.target.value);
  }

  handleOnUpdate(e, { width }) {
    this.setState({ width });
  }

  handleSearch(searchterm) {
    const { ws_id } = this.props;
    const application_id = this.props.activeWorkspace.cnc_application;
    const authKey = localStorage.getItem('x-auth-key');
    const { pathname } = this.props.location;
    if (authKey === null) {
      this.props.history.push('/auth/login');
    }
    const regex = /cmscontent\/(\w+)\/list/;
    let cm_content_type = pathname.match(regex); // get cmsContent type
    if (cm_content_type) {
      cm_content_type = cm_content_type[1];
    }
    // const cmspath = cm_content_type ? pathname : '/app/cmsContent';
    const {
      performersHeader,
      venueHeader,
      personsHeader,
      notificationHeader,
      cmscontentHeader,
      shopHeader,
    } = this.props;
    switch (pathname) {
      case '/app/performers/list': {
        const sort =
          performersHeader && performersHeader.sort
            ? performersHeader.sort
            : 'pf_name:asc';
        const filteredSeason = performersHeader
          ? performersHeader.event_id
          : null;
        this.props.fetchPerformers(
          authKey,
          ws_id,
          1,
          searchterm,
          sort,
          null,
          filteredSeason
        );
        break;
      }
      case '/app/venues/list': {
        const sort =
          venueHeader && venueHeader.sort ? venueHeader.sort : 'pl_name:asc';
        this.props.fetchVenues(authKey, ws_id, true, 1, searchterm, sort);
        break;
      }
      case '/app/advertising/list': {
        const sortByColumn =
          notificationHeader && notificationHeader.sort
            ? notificationHeader.sort
            : 'message_id:desc';
        this.props.fetchNotifications({
          authKey,
          ws_id,
          sortByColumn,
          searchterm,
        });
        break;
      }
      case '/app/persons/list': {
        const sort =
          personsHeader && personsHeader.sort
            ? personsHeader.sort
            : 'pe_name:asc';
        this.props.fetchPersons(authKey, ws_id, 1, searchterm, sort, null);
        break;
      }
      case '/app/cmscontent/list': {
        const { content_category, category_id } = this.props.config.config;
        const sort =
          cmscontentHeader && cmscontentHeader.sort
            ? cmscontentHeader.sort
            : 'cms_content_id:asc';
        this.props.fetchCmsContents({
          authKey,
          ws_id,
          sortByColumn: sort,
          searchterm,
          contentCategory: content_category,
          contentId: category_id,
        });
        break;
      }
      case '/app/product/list': {
        const sort =
          shopHeader && shopHeader.sort ? shopHeader.sort : 'product_id:asc';
        const data = {
          authKey,
          sortByColumn: sort,
          searchterm,
          ws_id,
        };
        this.props.fetchProducts(data);
        break;
      }
      case '/app/lottery/list': {
        // const sort = (shopHeader && shopHeader.sort) ? shopHeader.sort : 'product_id:asc';
        //= ({ authKey, ws_id, start })
        const data = {
          authKey,
          // sortByColumn: sort,
          ws_id,
          start: 1,
          searchterm,
        };
        this.props.fetchLotteries(data);
        break;
      }
      // case cmspath: { // cms conten list eg. /app/cmscontent/news/list
      //   const { content_category, category_id } = this.props.config.config;
      //   const sort = (cmscontentHeader && cmscontentHeader.sort) ? cmscontentHeader.sort : 'cms_content_id:asc';
      //   this.props.fetchCmsContents({
      //     authKey,
      //     ws_id,
      //     sortByColumn: sort,
      //     searchterm,
      //     start: 1,
      //     contentCategory: content_category,
      //     contentId: category_id,
      //     cm_content_type
      //   });
      //   break;
      // }
      case '/app/pricelist/list': {
        // const sort = (shopHeader && shopHeader.sort) ? shopHeader.sort : 'product_id:asc';
        // const data = {
        //   authKey,
        //   sortByColumn: sort,
        //   searchterm,
        //   ws_id
        // };
        // console.log('Price list search, Path: ', pathname);
        this.props.fetchPriceLists({ authKey, ws_id, searchterm, all: true });
        break;
      }
      case '/app/ticketing/tickets/list': {
        const sort =
          shopHeader && shopHeader.sort ? shopHeader.sort : 'event_id:asc';
        this.props.fetchProducts({
          authKey,
          sortByColumn: sort,
          direction: null,
          start: 1,
          searchterm,
          pr_type: 'entry',
          ws_id,
          module_id: 522,
        });
        break;
      }
      case '/app/voting/list': {
        // const sort = (shopHeader && shopHeader.sort) ? shopHeader.sort : 'product_id:asc';
        //= ({ authKey, ws_id, start })
        const data = {
          authKey,
          // sortByColumn: sort,
          ws_id,
          start: 1,
          searchterm,
          all: true,
        };
        this.props.fetchVotings(data);
        break;
      }
      case '/app/ticketing/seasoncard/list': {
        this.props.fetchCardInstances({
          moduleId: 37,
          authKey,
          ws_id,
          application_id,
          cardType: 'seasoncard',
          searchterm,
        });
        break;
      }
      case '/app/membership/list': {
        this.props.fetchCardInstances({
          moduleId: 39,
          authKey,
          ws_id,
          application_id,
          cardType: 'membership',
          searchterm,
        });
        break;
      }
      case '/app/membership/membercard/list': {
        this.props.fetchCardInstances({
          moduleId: 39,
          authKey,
          ws_id,
          application_id,
          cardType: 'membercard',
          searchterm,
        });
        break;
      }
      case '/app/productregistration/list': {
        this.props.fetchCardInstances({
          moduleId: 38,
          authKey,
          ws_id,
          application_id,
          cardType: 'registrationcard',
          searchterm,
        });
        break;
      }
      default:
        console.log('other routes');
    }
  }

  handleActiveAccordion() {
    this.setState(({ activeIndex }) => ({ activeIndex: -1 * activeIndex }));
  }

  renderMenuItems(menu, pathname) {
    return _.map(menu, (menuItem) => {
      switch (menuItem.type) {
        case 'list':
          return (
            <Menu.Item
              key={menuItem.type}
              name={T(menuItem.name)}
              content={T(menuItem.name)}
              as={NavLink}
              to={`${this.props.match.path}/${menuItem.link}`}
              headers={menuItem.header}
            />
          );
        case 'list2':
          return (
            <Menu.Item
              key={menuItem.type}
              name={T(menuItem.name)}
              content={T(menuItem.name)}
              as={NavLink}
              to={`${this.props.match.path}/${menuItem.link}`}
              headers={menuItem.header}
            />
          );
        case 'add':
          return (
            <Menu.Item
              key={menuItem.type}
              name={T(menuItem.name)}
              content={T(menuItem.name)}
              as={NavLink}
              to={`${menuItem.link.replace('/list', '/add')}`}
            />
          );
        case 'link_add':
          return (
            <Menu.Item
              key={menuItem.type}
              name={T(menuItem.name)}
              content={T(menuItem.name)}
              as={NavLink}
              to={`${this.props.match.path}/add`}
            />
          );
        case 'button': {
          return (
            <Menu.Item key={menuItem.type}>
              <Button primary onClick={menuItem.onClick}>
                <Icon name="refresh" /> {T(menuItem.name)}
              </Button>
            </Menu.Item>
          );
        }
        case 'search':
          return (
            <Menu.Item key={menuItem.type} position="right">
              <Input
                className="icon"
                icon="search"
                value={this.state.search}
                placeholder={T('Search ...')}
                onChange={(e, props) => this.onChange(e, props)}
                autoFocus
              />
            </Menu.Item>
          );
        case 'accordion':
          return pathname.indexOf('list') !== -1 ? (
            <div key="TeamFilterAccordion" style={{ position: 'relative' }}>
              <div key="largeScreenFilter" id="largeScreenFilter">
                <TeamListSeasonFilterController
                  isInline
                  header={this.props.performersHeader}
                />
              </div>
              <div key="smallScreenFilter" id="smallScreenFilter">
                <Accordion>
                  <Accordion.Title
                    active={menuItem.activeIndex === 1}
                    index={1}
                    onClick={this.handleActiveAccordion}
                  >
                    <Icon name="dropdown" />
                    {T('Filter Teams based on seasons')}
                  </Accordion.Title>
                  <Accordion.Content active={menuItem.activeIndex === 1}>
                    <TeamListSeasonFilterController
                      isInline={false}
                      header={this.props.performersHeader}
                    />
                  </Accordion.Content>
                </Accordion>
              </div>
            </div>
          ) : (
            <div key="empty-div" />
          );
        default:
          return <div />;
      }
    });
  }

  renderMenu(path, header, activeIndex) {
    const cmsPattern = /app\/cmscontent\/[a-z]*\/list/;
    switch (true) {
      case path.startsWith('/app/product/manage'):
        return this.renderMenuItems([
          { type: 'list', name: 'List', link: 'products/list' },
        ]);
      case path.startsWith('/app/pricelist/list'):
        // case path.startsWith('/app/ticketing/default/list'): //passed props makes it tricky to use it here
        return this.renderMenuItems([
          { type: 'add', name: 'Add', link: 'manage' },
          { type: 'search', name: 'Search' },
        ]);
      case path.startsWith('/app/venues/list'):
        return this.renderMenuItems([{ type: 'search', name: 'Search' }]);
      case path.startsWith('/app/ticketing/tickets/list'):
        return this.renderMenuItems([
          { type: 'list', name: 'List', link: 'tickets/list' },
          { type: 'search', name: 'Search' },
        ]);
      case path.startsWith('/app/ticketing/tickets/status'):
        return null;
      case path.startsWith('/app/lottery/list'):
        return this.renderMenuItems([
          { type: 'list', name: 'List', link: 'list' },
          { type: 'add', name: 'Add', link: 'manage' },
          { type: 'search', name: 'Search' },
        ]);
      case path.startsWith('/app/lottery/manage'):
        return this.renderMenuItems([
          { type: 'list', name: 'List', link: 'list' },
        ]);
      case path.startsWith('/app/performers/list'):
        return this.renderMenuItems(
          [
            { type: 'add', name: 'Add', link: 'add' },
            { type: 'accordion', name: 'Accordion', header, activeIndex },
            { type: 'search', name: 'Search', link: '' },
          ],
          path
        );
      case path.startsWith('/app/product/list'):
      case cmsPattern.test(path):
      case path.startsWith('/app/persons/list') &&
        this.props.isSuperAdmin === true:
        return this.renderMenuItems([
          // { type: 'list', name: 'List', link: 'list', header },
          { type: 'search', name: 'Search' },
        ]);
      case path.startsWith('/app/shop/list'):
        return this.renderMenuItems([
          { type: 'add', name: 'Add', link: 'manageshop' },
          // { type: 'search', name: 'Search' }
        ]);
      case path.startsWith('/app/shop/sales'):
      case path.startsWith('/app/shop/waitercalls'):
      case path.startsWith('/app/shop/home'): //shop or app setting doesn't need 'Add', 'List' or 'Search' options
        return null;
      case path.startsWith('/app/voting/list'):
        return this.renderMenuItems([
          { type: 'list', name: 'List', link: 'list' },
          { type: 'add', name: 'Add', link: 'manage' },
          { type: 'search', name: 'Search' },
        ]);
      case path.startsWith('/app/voting/manage'):
        return this.renderMenuItems([
          { type: 'list', name: 'List', link: 'list' },
        ]);
      case path.startsWith('/app/ticketing/seasoncard/list'):
        return this.renderMenuItems([
          { type: 'list', name: 'List Season Cards', link: 'seasoncard/list' },
          { type: 'add', name: 'Add Season Card', link: 'add' },
          { type: 'search', name: 'Search' },
        ]);
      case path.startsWith('/app/ticketing/seasoncard/add'):
        return this.renderMenuItems([
          { type: 'list', name: 'List Season Cards', link: 'seasoncard/list' },
        ]);
      case path.startsWith('/app/ticketing/seasoncard/edit/'):
        return this.renderMenuItems([
          { type: 'list', name: 'List Season Cards', link: 'seasoncard/list' },
        ]);
      case path.startsWith('/app/membership/list'):
        return this.renderMenuItems([
          { type: 'list', name: 'List Memberships', link: 'list' },
          { type: 'add', name: 'Add Membership', link: 'add' },
          {
            type: 'list2',
            name: 'List Membership Cardholders',
            link: 'membercard/list',
          },
          { type: 'search', name: 'Search' },
        ]);
      case path.startsWith('/app/membership/add'):
        return this.renderMenuItems([
          { type: 'list', name: 'List Memberships', link: 'list' },
          { type: 'link_add', name: 'Add Membership', link: 'add' },
          {
            type: 'list2',
            name: 'List Membership Cardholders',
            link: 'membercard/list',
          },
        ]);

      case path.startsWith('/app/membership/edit/'):
        return this.renderMenuItems([
          { type: 'list', name: 'List Memberships', link: 'list' },
          {
            type: 'list2',
            name: 'List Membership Cardholders',
            link: 'membercard/list',
          },
        ]);
      case path.startsWith('/app/membership/membercard/list'):
        return this.renderMenuItems([
          { type: 'list', name: 'List Memberships', link: 'list' },
          { type: 'link_add', name: 'Add Membership', link: 'add' },
          {
            type: 'list2',
            name: 'List Membership Cardholders',
            link: 'membercard/list',
          },
          { type: 'search', name: 'Search' },
        ]);
      case path.startsWith('/app/productregistration/list'):
        return this.renderMenuItems([
          {
            type: 'list',
            name: 'List Product Registrations',
            link: 'productregistration/list',
          },
          { type: 'add', name: 'Add Product Registration', link: 'add' },
          { type: 'search', name: 'Search' },
        ]);
      case path.startsWith('/app/productregistration/add'):
        return this.renderMenuItems([
          {
            type: 'list',
            name: 'List Product Registrations',
            link: 'productregistration/list',
          },
        ]);
      case path.startsWith('/app/productregistration/edit/'):
        return this.renderMenuItems([
          {
            type: 'list',
            name: 'List Product Registrations',
            link: 'productregistration/list',
          },
        ]);
      default:
        // return (this.renderMenuItems([
        //         { type: 'list', name: 'List', link: 'list' },
        //         { type: 'add', name: 'Add', link: 'add' },
        //         { type: 'search', name: 'Search', link: '' }
        //       ]));
        return null; // Not displaying any nav bar
    }
  }

  render() {
    const { activeIndex } = this.state;
    const { pathname } = this.props.location;
    const { cmscontentHeader } = this.props;
    return (
      <div style={{ paddingBottom: 50 }}>
        <Menu
          id="nav-bar"
          stackable
          fixed="top"
          style={{ paddingRight: 150, minHeight: 48, paddingTop: 2 }}
        >
          {this.renderMenu(pathname, cmscontentHeader, activeIndex)}
        </Menu>
      </div>
    );
  }
}

function mapStateToProps({
  workspace,
  performers,
  venues,
  persons,
  notifications,
  applications,
  cmsContent,
  //lottery,
  voting,
  shop,
  //users,
  auth,
}) {
  return {
    ws_id: workspace.ws_id,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    performersHeader: performers.headers,
    venueHeader: venues.header,
    personsHeader: persons.headers,
    notificationHeader: notifications.header,
    cmscontentHeader: cmsContent.headers,
    applications,
    isSuperAdmin: auth.isSuperAdmin,
    shopHeader: shop.headers,
    //lottery,
    voting,
    //users,
    //TODO do you need lottery and users hear
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchCmsContents,
    fetchPerformers,
    fetchVenues,
    fetchPersons,
    fetchProducts,
    fetchPriceLists,
    fetchLotteries,
    fetchVotings,
    fetchUsers,
    fetchCardInstances,
    fetchTicketingStatus,
  })(Nav)
);
